(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userSettingsGeneralSignatureControllerOld", userSettingsGeneralSignatureControllerOld);

	function userSettingsGeneralSignatureControllerOld($scope, $http, $filter, $q, $mdDialog, $state, $rootScope,
		coreData, coreDataSettings, errorHandling) {
		var vm = this;

		$scope.smtpAccounts = [];
		$scope.variables = {};
		$scope.setByDomainMap = { name: "SET_BY_DOMAIN_ADMIN", signatureGuid: "", signatureMapOption: 1 };
		$scope.availableMappings = [];
		$scope.signatureMappings = [];
		$scope.username = coreData.user.username;
		$scope.domain = coreData.user.domain;

		// Functions
		vm.reloadState = reloadState;
		vm.save = saveMappingsCard;
		vm.newSignature = newSignature;
		vm.editSignature = editSignature;
		vm.deleteItem = deleteItem;
		vm.signatureMapChanged = signatureMapChanged;
		$scope.getSignatureFromGuid = getSignatureFromGuid;

		activate();

		$rootScope.$on("user-signatures:changed",
			function () {
				$http.get("~/api/v1/settings/signature-mappings")
					.then(
						function (result) {
							$scope.signatureMappings = result.data.maps;
							initSignatures();
						}, errorHandling.report)
			});

		/////////////////////

		function activate() {
			var promises = [
				coreDataSettings.settingsData.signatureVariables,
				$http.get("~/api/v1/settings/signature-mappings"),
				coreDataSettings.init()
			];
			$q.all(promises)
				.then(function (success) {
					$scope.variables = success[0];
					$scope.signatureMappings = success[1].data.maps;

					initSignatures();
				}, errorHandling.report);
		}

		function reloadState(form) {
			activate();
			form.$setPristine();
		}

		function initSignatures() {
			$scope.availableMappings = [
				{ name: "NONE", signatureGuid: "", signatureMapOption: 0 },
				{ name: "USE_PRIMARY_SIGNATURE", signatureGuid: "", signatureMapOption: 1 },
				{ name: "USE_DOMAIN_SIGNATURE", signatureGuid: "", signatureMapOption: 3 }
			];

			$scope.signatures = coreDataSettings.userSignatures ? $.extend(true, [], coreDataSettings.userSignatures) : [];

			angular.forEach($scope.signatures, function (value) {
				$scope.availableMappings.push({ name: value.name, signatureGuid: value.guid, signatureMapOption: 2 });
			});

			var i;
			var varKeys = Object.keys($scope.variables);
			for (i = 0; i < varKeys.length; ++i) {
				$scope.variables[varKeys[i]] = $filter("translate")($scope.variables[varKeys[i]]);
			}

			for (i = 0; i < $scope.signatureMappings.length; i++) {
				if ($scope.signatureMappings[i].allowUsersToOverride)
					$scope.signatureMappings[i].signature = $scope.getSignatureFromGuid(
						$scope.signatureMappings[i].signatureGuid,
						$scope.signatureMappings[i].mapOption);
				else
					$scope.signatureMappings[i].signature = $scope.getSignatureFromGuid("", 3);
			}
		}

		function getSignatureFromGuid(guid, mapOption) {
			var i, value;
			if (mapOption === 2) {
				for (i = 0; i < $scope.availableMappings.length; ++i) {
					value = $scope.availableMappings[i];
					if (value.signatureGuid === guid && value.signatureMapOption === mapOption) {
						return value;
					}
				}
			} else {
				for (i = 0; i < $scope.availableMappings.length; ++i) {
					value = $scope.availableMappings[i];
					if (value.signatureMapOption === mapOption) {
						return value;
					}
				}
			}
		}

		function signatureMapChanged(index, signature) {
			$scope.signatureMappings[index].signatureGuid = $scope.signatureMappings[index].signature.signatureGuid;
			$scope.signatureMappings[index].mapOption = $scope.signatureMappings[index].signature.signatureMapOption;
		}

		function newSignature(ev) {
			$mdDialog.show({
				locals: {
					name: "",
					body: "",
					newItem: true,
					variables: $scope.variables,
					sigId: 0,
					domainOverride: ""
				},
				controller: "userEditSignatureController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/user-settings/signatures/edit-signature.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					if (modalSuccess.deleteItem) {
					} else {
						var name = modalSuccess.name;
						var body = modalSuccess.body;
						if (name !== "") {
							var params = { signatureConfig: { id: "", name: name, text: body } };
							$http
								.post("~/api/v1/settings/user-signature-put", params)
								.then(function (success) {
									var id = success.data.signatureID;
									var guid = success.data.signatureGuid;
									var newSignature = { id: id, guid: guid, name: name, text: body };

									if (!$scope.signatures) $scope.signatures = [];
									$scope.signatures.push(newSignature);
									if (!angular.isArray(coreDataSettings.userSignatures)) coreDataSettings.userSignatures = [];
									coreDataSettings.userSignatures.push(newSignature);
									coreDataSettings.userSignatures.sort(function (a, b) { return a.name.localeCompare(b.name); });
									$scope.availableMappings.push({ name: name, signatureGuid: guid, signatureMapOption: 2 });
								}, errorHandling.report);
						}
					}
				}, function () {
					// Cancel
				});
		}

		function editSignature(ev, signature) {
			$mdDialog.show({
				locals: {
					name: signature.name,
					body: signature.text,
					newItem: false,
					variables: $scope.variables,
					sigId: signature.id,
					domainOverride: ""
				},
				controller: "userEditSignatureController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/user-settings/signatures/edit-signature.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					if (modalSuccess.deleteItem) {
						var confirm = $mdDialog.confirmDeletion()
							.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: 1 }))
							.targetEvent(event);
						$mdDialog.show(confirm)
							.then(function () { vm.deleteItem(signature); }, function () {});

						
					} else {
						var name = modalSuccess.name;
						var body = modalSuccess.body;

						var updatedSignature = { id: signature.id, guid: signature.guid, name: name, text: body }
						var params = { signatureConfig: updatedSignature };
						$http.post("~/api/v1/settings/user-signature", params)
							.then(function () {
								var i;
								for (i = 0; i < $scope.signatures.length; ++i) {
									if ($scope.signatures[i].guid === updatedSignature.guid) {
										$scope.signatures[i] = updatedSignature;
										break;
									}
								}

								for (i = 0; i < $scope.availableMappings.length; ++i) {
									if ($scope.availableMappings[i].signatureGuid === updatedSignature.guid) {
										$scope.availableMappings[i].name = updatedSignature.name;
										$scope.availableMappings[i].signatureGuid = updatedSignature.guid;
										$scope.availableMappings[i].signatureMapOption = 2;
										break;
									}
								}

								angular.forEach($scope.signatureMappings, function (value, key) {
									if (value.signatureGuid === updatedSignature.guid) {
										value.signature.name = updatedSignature.name;
									}
								});

								coreDataSettings.userSignatures = $.extend(true, [], $scope.signatures);
							}, errorHandling.report);
					}
				}, function () {
					// Cancel
				});
		}

		function deleteItem(signature) {
			$http.post("~/api/v1/settings/user-signature-delete/" + signature.id)
				.then(function () {
					var i;
					for (i = 0; i < $scope.signatures.length; ++i) {
						if ($scope.signatures[i].guid === signature.guid) {
							$scope.signatures.splice(i, 1);
							break;
						}
					}
					for (i = 0; i < $scope.availableMappings.length; ++i) {
						if ($scope.availableMappings[i].signatureGuid === signature.guid) {
							$scope.availableMappings.splice(i, 1);
							break;
						}
					}
					angular.forEach($scope.signatureMappings, function (value, key) {
						if (value.signature.signatureGuid === signature.guid) {
							if (value.type === 4) {
								value.signatureGuid = "NONE";
								value.mapOption = 0;
								value.signature = $scope.getSignatureFromGuid(value.signatureGuid, value.mapOption);
							} else {
								value.signatureGuid = "USE_PRIMARY_SIGNATURE";
								value.mapOption = 1;
								value.signature = $scope.getSignatureFromGuid(value.signatureGuid, value.mapOption);
							}
						}
					});
					saveMappingsCard(true);
					coreDataSettings.userSignatures = $.extend(true, [], $scope.signatures);
				}, errorHandling.report);
		}

		function saveMappingsCard(stay) {
			$http.post("~/api/v1/settings/signature-mappings", { signatureMaps: $scope.signatureMappings })
				.then(onSaveSuccess, errorHandling.report);

			function onSaveSuccess() {
				vm.form.$setPristine();
				vm.form.$setUntouched();
			}
		}

	}
})();
(function () {
    "use strict";

    angular
        .module("smartermail")
		.controller("emailAttachmentsController", emailAttachmentsController);
	function emailAttachmentsController($scope, $mdDialog, $http, errorHandling, message) {
		$scope.controller = this;
		$scope.attachments = message.attachments || [];
		$scope.allAttachmentFile = null;

		for (let i = 0; i < $scope.attachments.length; i++) {
			const item = $scope.attachments[i];
			if (item.allAttachments)
				$scope.allAttachmentFile = item;
		}

        $scope.close = function () {
            $mdDialog.cancel();
        };
    }
})();
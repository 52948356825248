(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedEditSubscriberController", sharedEditSubscriberController);

	function sharedEditSubscriberController($scope, $mdDialog, $q, $http, $state, $filter, emailValidationService,
		coreDataDomainSettings, id, email, mailingListId, successHandling, errorHandling) {
		var vm = this;
		vm.mailingListId = Number(mailingListId);

		$scope.isDomain = $state.params.type === 'domain';
		$scope.subscriber = {};
		$scope.mailingLists = [];
		$scope.selectedTab = 0;
		$scope.subscriberId = id;
		$scope.subscriberEmail = email;

		// Functions
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.resetBounces = resetBounces;
		$scope.openMessage = openMessage;
		$scope.getInvalidEmails = getInvalidEmails;
		$scope.fixInvalidEmail = fixInvalidEmail;
		$scope.removeInvalidEmail = removeInvalidEmail;

		activate();

		///////////////////////

		function activate() {
			loadSubscriber();
		}

		function save() {
			$scope.subscriber.subscribedLists = [];
			angular.forEach($scope.subscriber.subscribedListObject, function (value, key) { if (value) $scope.subscriber.subscribedLists.push(parseInt(key)); });

			$scope.subscriber.subscribedDigests = [];
			angular.forEach($scope.subscriber.subscribedDigestObject, function (value, key) { if (value) $scope.subscriber.subscribedDigests.push(parseInt(key)); });

			$mdDialog.hide({ subscriber: $scope.subscriber });
		}

		function cancel() {
			$mdDialog.cancel();
		}


		function openMessage(miniCard) {
			//$localStorage.mailPopout = { id: miniCard.messageUID, folder: 'Previously+Sent+Messages', owner: miniCard.mailListName.toLowerCase() + "@" + coreDataDomainSettings.domain.toLowerCase() };
			//window.open(stSiteRoot + 'interface/root#/popout/email/' + miniCard.messageUID + "?folder=Previously+Sent+Messages&owner=" + miniCard.mailListName.toLowerCase() + "@" + coreDataDomainSettings.domain.toLowerCase(),
			//	"emailID" + miniCard.messageUID,
			//	'resizable=1, ' + popupService.dimensions.email);
		}

		// Here we try to map the bounce to a message if possible.
		const createSubscriberBounceLog = function (subscriber) {
			const eventList = [];
			const defaultMailingListName = $scope.mailingLists.find(x => x.id === vm.mailingListId).listAddress;
			subscriber.messageLog.forEach(log => {
				if (log.mailingListID === vm.mailingListId) {
					eventList.push({
						ts: log.sentAt,
						type: 0,
						listId: log.mailingListID,
						subject: log.subject || $filter("translate")("SUBJECT_NOT_AVAILABLE")
					});
				}
			});
			subscriber.bounces.forEach(bounce => {
				eventList.push({
					ts: bounce.bounceTime,
					listId: bounce.id,
					type: 1
				});
			});
			eventList.sort((a, b) => a.ts.localeCompare(b.ts));
			let lastMessage = {
				listName: defaultMailingListName
			};
			const bounceLog = [];

			for (let idx = 0; idx < eventList.length; idx++) {
				let evt = eventList[idx];
				if (evt.type === 1) {
					if (!lastMessage.ts) {
						lastMessage.ts = evt.ts;
						lastMessage.subject = $filter("translate")("SUBJECT_NOT_AVAILABLE");
					}
					lastMessage.bounces.push(evt.ts);
					bounceLog.push({ listName: lastMessage.listName, ts: evt.ts, subject: lastMessage.subject });
				} else {

					lastMessage.ts = evt.ts;
					lastMessage.subject = evt.subject;
					lastMessage.bounces =[];
				}
			}

			return bounceLog;
		}
		function loadSubscriber() {
			var defer = $q.defer();

			var promises = [];

			promises.push($http.get('~/api/v1/settings/domain/mailing-lists/list'));
			promises.push(coreDataDomainSettings.settingsData.customFields);
			promises.push($http.get("~/api/v1/settings/domain/mailing-lists/subscribers/" + $scope.subscriberEmail + "/" + vm.mailingListId));

			$q.all(promises)
				.then(function (success) {
					$scope.mailingLists = success[0].data.items || [];
					$scope.mailingLists.sort(function (listA, listB) {
						return listA.listAddress.localeCompare(listB.listAddress);
					});
					
					$scope.customFields = $.extend(true, [], success[1]);
					$scope.subscriber = success[2].data;
					$scope.subscriber.subscribedListObject = {};
					angular.forEach($scope.subscriber.subscribedLists, function (id) { $scope.subscriber.subscribedListObject[id] = true; });
					$scope.subscriber.subscribedDigestObject = {};
					angular.forEach($scope.subscriber.subscribedDigests, function (id) { $scope.subscriber.subscribedDigestObject[id] = true; });

					// Insert defaults

					// Set Mail list names for message logs
					angular.forEach($scope.subscriber.messageLog, function (log) {
						angular.forEach($scope.mailingLists, function (list) {
							if (list.id === log.mailingListID) {
								log.mailListName = list.listAddress;
								return;
							}
						});
					});

					$scope.bounceLog = createSubscriberBounceLog($scope.subscriber);
					defer.resolve();
				}, function () {
					defer.reject();
				});
		}
		function resetBounces(form) {
			$http.post("~/api/v1/settings/domain/mailing-lists/subscriber-clear-bounce-count", { input: $scope.subscriberEmail })
				.then(function () {
					form.$setDirty();
					successHandling.report("ACTION_SUCCEEDED");
					$scope.subscriber.bounces = [];
					$scope.subscriber.bounceCount = 0;
					$scope.bounceLog = [];
				}, errorHandling.report);
		}

		function getInvalidEmails(userForm) {
			$scope.invalidEmails = [];
			$scope.emailAddresses = userForm.emailAddresses.$viewValue;

			var emails = $scope.emailAddresses.split("\n");

			for (var i = 0; i < emails.length; i++) {
				if (!emails[i] && i == emails.length - 1) continue;
				if (!emailValidationService.isValidEmail(emails[i]))
					$scope.invalidEmails.push({ invalidEmailIndex: i, emailToFix: emails[i] });
			}
		}

		function fixInvalidEmail(userForm, email) {
			var emails = $scope.emailAddresses.split("\n");

			emails[email.invalidEmailIndex] = email.emailToFix;

			$scope.emailAddresses = emails.join("\n");
			userForm.emailAddresses.$viewValue = $scope.emailAddresses;
			userForm.emailAddresses.$modelValue = $scope.emailAddresses;
			userForm.emailAddresses.$commitViewValue();
			userForm.emailAddresses.$rollbackViewValue();

			getInvalidEmails(userForm);
		}

		function removeInvalidEmail(userForm, email) {
			var emails = $scope.emailAddresses.split("\n");

			emails.splice(email.invalidEmailIndex, 1);

			$scope.emailAddresses = emails.join("\n");
			userForm.emailAddresses.$viewValue = $scope.emailAddresses;
			userForm.emailAddresses.$modelValue = $scope.emailAddresses;
			userForm.emailAddresses.$commitViewValue();
			userForm.emailAddresses.$rollbackViewValue();

			getInvalidEmails(userForm);
		}
	}

})();

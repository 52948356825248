(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("documentStatusDlgController", documentStatusDlgController);

    function documentStatusDlgController($scope, $mdDialog, $http, fileid) {
        $scope.controller = this;
        $scope.selectedNode = "";
        $scope.title = "Document Status";
        $scope.fileid = fileid; // Ensure fileid is available in the scope

        // Function to close and force save the document
        $scope.done = function () {
            $mdDialog.hide({
                'success': true,
            });

            // Post the file ID to the API on success
            $http.post('~/api/v1/settings/ForceSaveAndCloseDocument', JSON.stringify(fileid), { headers: { 'Content-Type': 'application/json' } })
                .then(function (response) {
                    console.log('File ID posted successfully:', response.data);
                })
                .catch(function (error) {
                    console.error('Error posting file ID:', error);
                });
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        };

        // Function for document recovery actions
        $scope.documentrecoveryactions = function (fileid, action, newname) {
            newname = newname || "";

            $http.post('~/api/v1/settings/document-recovery-actions', { fileid: JSON.stringify(fileid), action: action, newname: JSON.stringify(newname) }, { headers: { 'Content-Type': 'application/json' } })
                .then(function (response) {
                    console.log('Document recovery action posted successfully:', response.data);
                    $mdDialog.hide(); // Close the dialog after the action is successful
                })
                .catch(function (error) {
                    console.error('Error posting document recovery action:', error);
                    // Optionally, you could keep the dialog open or show an error message if the request fails
                });
        };

        // Function to handle the submit button click
        $scope.submit = function (selectedOption, newname) {
            switch (selectedOption) {
                case "1":
                    // Recover document and overwrite
                    $scope.documentrecoveryactions(fileid, 1);
                    break;
                case "2":
                    // Recover document and rename
                    $scope.documentrecoveryactions(fileid, 2, newname);
                    break;
                case "3":
                    // Download
                    $scope.getLink(); // Implement this function if not already
                    break;
                case "4":
                    // Recover and delete recovered copy
                    $scope.documentrecoveryactions(fileid, 3);
                    break;
            }
            $mdDialog.hide(); // Close the dialog after the action
        };

        // Function to get the download link (assuming you have a function for this)
        $scope.getLink = function () {
            // Your logic for getting the download link
            // After getting the link, you can close the dialog if necessary
            $mdDialog.hide();
        };
    }
})();

(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("onlyOfficeEditorController", onlyOfficeEditorController);

    function onlyOfficeEditorController(
        $rootScope, $scope, $mdDialog, $timeout, $filter,
        $stateParams, $q, $http, $translate, $sce, coreData,
        coreDataFileStorage, coreDataCategories, userDataService,
        authStorage, errorHandling, apiCategories, apiNoteSources,
        errorMessageService, themesService, fileInfoProvider, toaster, localeInfoService
    ) {
        var vm = this;
        var docdata;
        var language = localeInfoService.language;
        var maxtries = 100;

        //const HASH_STRING = window.location.hash;
        //const PARAMS_STRING = HASH_STRING.split('?')[1];
        //const URL_PARAMS = new URLSearchParams(PARAMS_STRING);
        //const FILE_ID = URL_PARAMS.get('file_id');
        //const ORG_FILE_ID = URL_PARAMS.get('file_id');

        //const DOWNLOAD_LINK = decodeURIComponent(URL_PARAMS.get('src'));
        //const SMARTERMAIL_URL = new URL(DOWNLOAD_LINK);
        //const BASE_URL = `${SMARTERMAIL_URL.protocol}//${SMARTERMAIL_URL.hostname}`;

        const FILE_JSON = {
            "fileactions": [
                {
                    "type": "pdf",
                    "actions": ["view"],
                    "convert": ["pdf", "pdfa"]
                },
                {
                    "type": "word",
                    "actions": ["view", "auto-convert"],
                    "convert": ["docx", "docxf", "docm", "dotm", "dotx", "epub", "fb2", "html", "odt", "ott", "pdf", "pdfa", "rtf", "txt"]
                },
                {
                    "type": "word",
                    "actions": ["view", "edit"],
                    "convert": ["docx", "docxf", "dotm", "dotx", "epub", "fb2", "html", "odt", "ott", "pdf", "pdfa", "rtf", "txt", "doc"]
                },
                {
                    "type": "cell",
                    "actions": ["view", "lossy-edit"],
                    "convert": ["xlsx", "ods", "ots", "pdf", "pdfa", "xlsm", "xltm", "xltx"]
                },
                {
                    "type": "cell",
                    "actions": ["view", "auto-convert"],
                    "convert": ["xlsx", "ods", "ots", "pdf", "pdfa", "xlsm", "xltm", "xltx"]
                },
                {
                    "type": "slide",
                    "actions": ["view", "auto-convert"],
                    "convert": ["pptx", "odp", "otp", "pdf", "pdfa", "potm", "potx", "ppsm", "ppsx", "pptm"]
                },
                {
                    "type": "slide",
                    "actions": ["view", "lossy-edit", "auto-convert"],
                    "convert": ["pptx", "otp", "pdf", "pdfa", "potm", "potx", "ppsm", "ppsx", "pptm"]
                },
                {
                    "type": "slide",
                    "actions": ["view", "edit"],
                    "convert": ["pptx", "odp", "otp", "pdf", "pdfa", "potm", "potx", "ppsm", "ppsx", "pptm"]
                }
            ]
        }
        

        function generateRandom12DigitNumber() {
            const MIN = 100000000000;
            const MAX = 999999999999;
            return String(Math.floor(Math.random() * (MAX - MIN + 1)) + MIN);
        } 

        $scope.onlyOfficeEdit =  function (file) {    
            var downloadLink = window.location.href.substring(0, window.location.href.indexOf("/interface/root#")) + '/api/v1/filestorage/' + file.id + "/download" + "?token=" + authStorage.getToken();
            docdata = searchByFileExtension(file.type);
            var dict = {
                "filetype": file.type,
                "key": generateRandom12DigitNumber(),
                "title": file.fileName,
                "fileName": file.fileName,
                "url": downloadLink,
                "doctype": String(docdata[0].type),
                "callbackUrl": `${window.location.origin}/api/v1/settings/onlyoffice-callback/${authStorage.getUserEmail()}/${file.id}`,
                "lang": language
            };

            $http.post("~/api/v1/settings/onlyoffice-jwt", dict)
                .then(response => {
                    var jwtToken = response.data.message;
                    initDocEditor(jwtToken, file);
                })
                .catch(error => console.log("Error obtaining JWT token:", error));
        };

        function searchByFileExtension(fileExtension) {
            return FILE_JSON.fileactions.filter(item => item.convert.includes(fileExtension));
        }

        

        function getOnlyOfficeURL() {
            return $http.get("~/api/v1/settings/onlyoffice-url")
                .then(response => {
                    if (response.data && response.data.message) {
                        return response.data.message;
                    } else {
                        throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
                    }
                })
                .catch(error => {
                    console.error("Error obtaining OnlyOffice URL:", error);
                    throw error;
                });
        }

        function initDocEditor(jwtToken, file) {
            var onlyofficeUrl = getOnlyOfficeURL();

            var newWindow = window.open(coreData.baseUrl + '/interface/onlyoffice-editor');
            maxtries = 100;
            doinit(newWindow, onlyofficeUrl, jwtToken, file);

           

        }
        function doinit(newWindow, onlyofficeUrl, jwtToken, file) {
            if (newWindow && newWindow.setHTML) {
                newWindow.initEditor(onlyofficeUrl, jwtToken, file);
                newWindow.focus();
            } else {
                maxtries--;
                if (maxtries > 0) {
                    setTimeout(() => doinit(newWindow, onlyofficeUrl, jwtToken, file), 100);
                } else {
                    newWindow.close();
                }
            }

        }
    }
    
})();

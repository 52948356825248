(function () {
	"use strict";

	angular
		.module("smartertools")
		.directive("stPasswordRequirements", stPasswordRequirements);

	function stPasswordRequirements($compile, $http, $q, $log, $timeout, $filter, $translate, errorHandling, authStorage) {
		return {
			restrict: "EA",
			scope: {
				passwordReqApi: "@",
				newPasswordId: "@",
				username: "=?",
				passwordMeetsReq: "=",
				disabledChecks: "=?",
				fullChecks: "=?"
			},
			template: '\
            <div class="password-req-list">\
			<div class="pass-req-title" ng-if="requirements.length > 0" translate-once="PASSWORD_REQUIREMENTS_TITLE">test</div>\
			<div class="pass-requirement" ng-repeat="r in requirements" id="{{r.id}}" ng-class="{\'req-meets-true\': (r.met === true), \'req-meets-false\': (r.met === false)}" ng-bind-html="r.name"><div class="req-circle"></div></div>\
            </div>',
			link: function (scope, element, attrs, ctrl) {
				//Initial setup stuff.
				scope.useDefinedUsername = angular.isDefined(scope.username);
				scope.username = scope.useDefinedUsername ? scope.username : "";
				scope.fullChecks = angular.isDefined(scope.fullChecks) ? scope.fullChecks : false;

				scope.defer = $q.defer();
				var userDataService = null;
				var passReqElem = $(".password-req-list");
				if (!passReqElem || !passReqElem.injector()) {
					passReqElem = $("body");
				}
				if (passReqElem.injector().has("userDataService")) 
					userDataService = passReqElem.injector().get("userDataService");

				scope.requirements = [];

				scope.$on("passwordCheckCallback", function (e, reason) {
					if (!reason || !reason.data || !reason.data.message)
						return;

					if (reason.data.message.indexOf("PASSWORD_RESET_REQUIRE_NOT_USED_PREVIOUSLY") > -1) {
						$("#NOT_PREVIOUSLY_USED").addClass("req-meets-false");
						$("#NOT_PREVIOUSLY_USED").removeClass("req-meets-true");
					} else {
						$("#NOT_PREVIOUSLY_USED").removeClass("req-meets-false");
						$("#NOT_PREVIOUSLY_USED").addClass("req-meets-true");
					}

					if (reason.data.message.indexOf("PASSWORD_RESET_REQUIRE_COMMON") > -1) {
						$("#NOT_COMMON").addClass("req-meets-false");
						$("#NOT_COMMON").removeClass("req-meets-true");
					} else {
						$("#NOT_COMMON").removeClass("req-meets-false");
						$("#NOT_COMMON").addClass("req-meets-true");
					}
				});

				//Make API call to get requirements.
				const passwordReq = authStorage.getPasswordRequirements();
				if (passwordReq) {
					onComplete({ data: passwordReq });
				} else {
					$http.get(scope.passwordReqApi).then(onComplete, errorHandling.report);
				}

				function onComplete(success) {
					scope.username = scope.useDefinedUsername ? scope.username : userDataService.user.username;
					if (success.data.reqCapital && (!scope.disabledChecks || scope.disabledChecks["reqCapital"] !== false)) {
						scope.requirements.push({ id: "UPPERCASE", name: $filter("translate")("PASSWORD_REQUIREMENTS_UPPERCASE"), regex: /(?=.*[A-Z])/, met: false });
					}
					if (success.data.reqLower && (!scope.disabledChecks || scope.disabledChecks["reqLower"] !== false)) {
						scope.requirements.push({ id: "LOWERCASE", name: $filter("translate")("PASSWORD_REQUIREMENTS_LOWERCASE"), regex: /(?=.*[a-z])/, met: false });
					}
					if (success.data.reqNotUsername && userDataService && (!scope.disabledChecks || scope.disabledChecks["reqNotUsername"] !== false)) {
						scope.requirements.push({ id: "NOT_USERNAME", name: $filter("translate")("PASSWORD_REQUIREMENTS_NOT_USERNAME"), regex: new RegExp("^((?!" + scope.username + ").)*$", "i"), met: false, username: true });
					}
					if (success.data.reqNumber && (!scope.disabledChecks || scope.disabledChecks["reqNumber"] !== false)) {
						scope.requirements.push({ id: "NUMBER", name: $filter("translate")("PASSWORD_REQUIREMENTS_NUMBER"), regex: /(?=.*[0-9])/, met: false });
					}
					if (success.data.reqSymbol && (!scope.disabledChecks || scope.disabledChecks["reqSymbol"] !== false)) {
						scope.requirements.push({ id: "SYMBOL", name: $filter("translate")("PASSWORD_REQUIREMENTS_SYMBOL"), regex: /(?=.*[^a-zA-Z\d\s:])/, met: false });
					}
					if (success.data.reqLength > 0 && (!scope.disabledChecks || scope.disabledChecks["reqLength"] !== false)) {
						scope.requirements.push({ id: "LENGTH", name: $filter("translate")("PASSWORD_REQUIREMENTS_LENGTH", { length: success.data.reqLength }), regex: new RegExp("^.{" + success.data.reqLength + ",}$"), met: false });
					}
					if (scope.fullChecks && success.data.reqNotCommon && (!scope.disabledChecks || scope.disabledChecks["reqNotCommon"] !== false)) {
						scope.requirements.push({ id: "NOT_COMMON", name: $filter("translate")("PASSWORD_REQUIREMENTS_NOT_COMMON"), regex: null, met: null });
					}
					if (scope.fullChecks && success.data.reqNotPreviouslyUsed && (!scope.disabledChecks || scope.disabledChecks["reqNotPreviouslyUsed"] !== false)) {
						scope.requirements.push({ id: "NOT_PREVIOUSLY_USED", name: $filter("translate")("PASSWORD_REQUIREMENTS_NOT_PREVIOUSLY_USED"), regex: null, met: null });
					}

					if (scope.requirements.length === 0)
					{
						scope.passwordMeetsReq = true;
						return;
					}

					$(scope.newPasswordId).on("input", function (e) {
                        if ($("#NOT_COMMON").hasClass("req-meets-false")) {
                            $("#NOT_COMMON").removeClass("req-meets-false");
						}
						if ($("#NOT_PREVIOUSLY_USED").hasClass("req-meets-false")) {
							$("#NOT_PREVIOUSLY_USED").removeClass("req-meets-false");
						}
						var pass = $(this).val();
						if (pass === "") {
							scope.passwordMeetsReq = false;
							scope.requirements.forEach(function (req) {
								req.met = false;
							});
							scope.$apply();
							return;
						}

						var meetReq = true;

						scope.requirements.forEach(function (req) {
							var stripUsername = scope.useDefinedUsername ? scope.username : userDataService.user.username;
							if (req.username) {
								stripUsername = stripUsername ? stripUsername.split("@") : [];
								if (stripUsername[0]) {
									stripUsername = stripUsername[0];
								}
								req.regex = new RegExp("^" + (stripUsername) + "$", "i");
								req.met = !req.regex.test(pass);
								if (req.met) {
									req.regex = new RegExp("^" + (scope.username) + "$", "i");
									req.met = !req.regex.test(pass);
								}
							} else {
								if (req.regex)
									req.met = req.regex.test(pass);
							}

							if (req.met === false && meetReq && req.id !== "NOT_COMMON" && req.id !== "NOT_PREVIOUSLY_USED") 
								meetReq = false;
						});
						if (scope.passwordMeetsReq !== meetReq) {
							scope.passwordMeetsReq = meetReq;
						}

						scope.$apply();
					});
				}
			}
		};
	}
})();